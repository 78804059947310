<template>
  <main class="addon">
    <template v-if="this['addon/isLoadingAddons']">
      <div class="h-screen">
        <loading />
      </div>
    </template>
    <template v-else>
      <processSteps />

      <section class="container flex">
        <div class="addon__body__rooms flex__main-panel lg:pr-12">
          <addonContent></addonContent>
        </div>
        <div class="addon__body__total flex__aside-panel lg:pl-12">
          <orderTotalAside></orderTotalAside>
        </div>
      </section>
    </template>
  </main>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations, mapGetters } from 'vuex'
import _ from 'lodash'
import processSteps from '@/components/processSteps'
import addonContent from '@/views/Addon/components/addonContent'
import orderTotalAside from '@/components/orderTotalAside'
import loading from '@/components/loading'

export default {
  name: 'Addon',
  metaInfo () {
    return {
      title: this.$t('page.bookAddon'),
      meta: [
        {
          'property': 'og:title',
          'content': this.$t('page.bookAddon'),
          'vmid': 'og:title'
        },
        {
          'property': 'og:site_name',
          'content': this.$t('page.bookAddon'),
          'vmid': 'og:site_name'
        }
      ]
    }
  },
  components: {
    processSteps,
    addonContent,
    orderTotalAside,
    loading
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'addon/fetchExperiences'
    ]),
    ...mapMutations([
      'SET_STEP',
      'SET_IS_FETCHED_ADDON',
      'addon/SET_ADDON',
      'addon/IS_LOADING_ADDON'
    ]),
    skipStep: function () {
      this['SET_STEP'](3)
      this.$router.replace({
        name: 'Payment',
        params: {
          hotelId: this.hotelId
        }
      })
    },
    checkIsNeedSkip: function () {
      if (this['addon/addons'].length === 0) {
        this.skipStep()
      } else {
        this['addon/IS_LOADING_ADDON'](false)
      }
    }
  },
  computed: {
    ...mapGetters([
      'isSuper',
      'hotelId',
      'order/plans',
      'addon/addons',
      'hotel/isSkipAddon',
      'search/dateRange',
      'isFetchedAddon',
      'addon/isLoadingAddons'
    ]),
    displayDate: function () {
      const start = this.$dayjs(this['search/dateRange'].start).format('YYYY/MM/DD')
      const end = this.$dayjs(this['search/dateRange'].start).format('YYYY/MM/DD')
      return `${start} - ${end}`
    }
  },
  activated () {
    if (!this.isSuper && _.isEmpty(this['order/plans'])) {
      this.$router.replace({
        name: 'Hotel',
        params: {
          hotelId: this.hotelId
        }
      })
    }
    if (!this.isFetchedAddon) {
      this['SET_IS_FETCHED_ADDON'](true)
      this['addon/IS_LOADING_ADDON'](true)
      this['addon/fetchExperiences']().then(res => {
        if (res && res.data) {
          console.debug('fetch addons: ', res.data.data)
          this['addon/SET_ADDON'](res.data.data)
          this.checkIsNeedSkip()
        }
      })
    } else {
      this.checkIsNeedSkip()
    }
    this['SET_STEP'](2)
  },
  deactivated () {
  }
}
</script>

<style lang="sass">
// .addon
//   &__body
//     &__rooms
//       width: 100%
//       @screen lg
//         max-width: calc(100% - 400px)
//     &__total
//       width: auto
//       @screen lg
//         flex-shrink: 0
//         width: 400px
</style>
