var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addon-content" },
    [
      _c("h2", { staticClass: "mb-24 md:mb-40 font-medium" }, [
        _vm._v(_vm._s(_vm.$t("addAddons")))
      ]),
      _vm._l(_vm.sortedAddons, function(addon) {
        return _c("addonCard", { key: addon.id, attrs: { addon: addon } })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }