<template>
  <div
    :class='{"is-sold-out": isSoldOut}'
    class='addon-card relative mb-24 md:mb-40'>
    <div class="mb-8 hidden md:block">
      <span v-if="addon.is_hotel_self_addon" class="os-tag text-sm os-tag-solid os-tag-green inline-block font-bold">{{ $t('primeGoods') }}</span>
      <h4 class='text-grey-darker font-semibold inline-block mr-88' :class="{selfHostTitle: addon.is_hotel_self_addon}">{{ addon.name | contentParser }}</h4>
    </div>
    <div class="text-sm text-grey-darkest mb-8" v-if="!addon.is_hotel_self_addon">{{ $t('addon.travelRegistration') }}</div>
    <div class="addon-card__block">
      <div class="addon-card__block__head flex flex-col md:flex-row lg:flex-col xl:flex-row md:border-b-1 border-grey-lighter">
        <div class="addon-card__block__head__gallery">
          <div class="w-full h-full bg-cover bg-center cover" :style="{backgroundImage: `url('${addon.cover_images[0]}')`}" @click.stop="openPhotoSwipe()">
            <div class="zoomMask">
              <i class="owl-search"></i>
            </div>
          </div>
          <photoSwipe ref="photoSwipe" :photos="addon.cover_images"></photoSwipe>
        </div>
        <div class="addon-card__block__head__selections w-full pt-16 pb-0 md:p-16 flex flex-col justify-between">
          <div class="addon-card__block__head__selections__row mb-24">
            <div class="block md:hidden">
              <span v-if="addon.is_hotel_self_addon" class="os-tag text-sm os-tag-solid os-tag-green inline-block font-bold">{{ $t('primeGoods') }}</span>
              <h4 class='text-grey-darker font-semibold mb-8 inline-block' :class="{selfHostTitle: addon.is_hotel_self_addon}">{{ addon.name | contentParser }}</h4>
            </div>
            <span class='text-sm block' v-if="selectedSession">{{ selectedCurrency }} <big class='text-blue text-lg font-medium'>{{ selectedSession.price_without_fee | currency({ inputCurrency: selectedSession.currency, isBonus: true }) }}</big> / {{ $t(addon.unit) }}</span>
            <div
              v-if='isSoldOut'
              class='addon-card__block__sold-out os-tag os-tag-outline os-tag-red rounded-full text-sm static md:absolute'>{{ $t('endSale') }}</div>
            <!-- <div
              v-if='isSoldOut && $dayjs(addon.enable_booking_start_date) > $dayjs()'
              class='addon-card__block__sold-out os-tag os-tag-outline os-tag-red rounded-full text-sm absolute'> 住宿日期不可訂</div> -->
          </div>

          <div class="addon-card__block__head__selections__row flex flex items-start justify-between mb-24 flex-1">
            <div v-show="!isGoods" class="addon-card__block__head__selections__time text-sm flex flex-col">
              <label>{{ $t('totalTime') }}</label>
              <span> {{ formatSpendTime(addon.spend_time) }}</span>
            </div>
            <div v-if="!addon.is_hotel_self_addon && addon.unit_type === 'group'" class="addon-card__block__head__selections__limit text-sm flex flex-col">
              <label>{{ restrictI18n }}</label>
              <span>{{ addon.person_limit.min }} - {{ addon.person_limit.max }} {{ isExpGroupType ? '' : $t(addon.unit) }}</span>
            </div>
          </div>

          <div class="addon-card__block__head__selections__row flex text-sm mb-16 flex-col">
            <label>{{ isGoods ? $t('goodsDate') : $t('travelDate') }}</label>
            <!--
            <v-date-picker
              v-model='selectedDate'
              isRequired
              :themeStyles='datePickerStyles'
              :disabledAttribute="datePickerStyles.disabledAttribute"
              :showDayPopover="false"
              :datePickerShowDayPopover="false"
              :attributes="attributes"
              :availableDates="availableDates"
              :tintColor="owlsight.colors.blue"
              popoverVisibility="focus">
              <label class="input input-icon" slot-scope="props">
                <input type="text" placeholder="選擇日期" :value="props.inputValue" readonly>
                <i class="owl-calendar"></i>
              </label>
            </v-date-picker>
            -->
            <v-date-picker
              v-model="selectedDate"
              is-required
              :attributes="attributes"
              :available-dates="availableDates"
            >
              <template #default="{ togglePopover }">
                <label
                  class="input input-icon addon-card__element__datepicker"
                  @click="togglePopover"
                >
                  {{ $dayjs(selectedDate).format('YYYY/MM/DD') }}
                </label>
                <i class="owl-calendar" @click.stop />
              </template>
            </v-date-picker>
          </div>

          <div v-show="!isGoods" class="addon-card__block__head__selections__row flex text-sm mb-16 flex-col">
            <label>{{ $t('selectionPeriod') }}</label>
            <multiSelect v-model="selectedSession"
              :options="sessionsOptions"
              :allow-empty="false"
              :searchable="false"
              :show-labels="false"
              :showPointer="isTouchDevice ? false : true"
              :placeholder="$t('pleaseSelectATimeSlot')"
              :disabled="isTimeNull">
              <template slot="option" slot-scope="{ option }">
                {{ parseTime(option.time) }} - {{ $t('remain') }} {{ remainStock(option) }}
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                <i class="owl-circle-clock-o text-grey mr-4" />
                <span class="text-md">{{ parseTime(option.time) }}</span>
              </template>
            </multiSelect>
          </div>

          <!-- <div class="addon-card__block__head__selections__row flex text-sm mb-16 flex-col">
            <label>參加人數</label>
            <vue-numeric-input
              class='w-full'
              v-model="people"
              :min="addon.person_limit.min"
              :max="addon.person_limit.max"
              :step="1" />
          </div> -->

          <div class="addon-card__block__head__selections__row flex text-sm flex-col">
            <div class="stock-label mb-8">
              <label class='mr-16'>{{ $t('quantity') }} ({{ $t(addon.unit) }})</label>
              <small class='text-sm text-grey'>{{ $t('stocks') }}：{{ inStock }}</small>
            </div>
            <div class="stock-btns flex items-center flex-col sm:flex-row">
              <vue-numeric-input
                class='w-half sm:mr-8'
                v-model="number"
                :min="personLimit"
                :max="inStock"
                :step="1" />
              <button
                class="addon-btn btn btn-ghost btn-blue btn-xs w-full sm:w-1/2 sm:ml-8"
                @click="onAddAddon(selectedSession, addon.unit)"
                :disabled="cannotAddToCart">
                {{ $t('addAddons') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="addon-card__block__body flex flex-col md:flex-row md:items-end md:justify-between py-16 md:px-16">
        <div class="addon-card__block__body__content" :class="{'w-full': addon.note}">
          <div v-if="addon.introduction" class="mb-8" :class="{'border-b border-grey-lighter mb-16': addon.note}">
            <div v-if="addon.note" class="text-sm text-grey-darker font-bold mb-8"><i class="owl-caret-right"></i>{{ $t('introduction') }}</div>
            <p class='whitespace-pre-wrap text-sm text-grey-darker leading-normal mb-16' v-html="$options.filters.contentParser(addon.introduction)"></p>
          </div>
          <div v-if="addon.note" class="mb-8">
            <div class="text-sm text-grey-darker font-bold mb-8"><i class="owl-caret-right"></i>{{ $t('note') }}</div>
            <p class='whitespace-pre-wrap text-sm text-grey-darker leading-normal mb-16' v-html="$options.filters.contentParser(addon.note)"></p>
          </div>

          <div
            v-if='hasCancelPolicy'
            @click="openCancelPolicy"
            class="addon-card__block__body__content__cancel-policy text-sm text-grey-dark cursor-pointer inline-block">
            <span>{{ $t('cancelPolicy') }}</span>
            <i class="owl-status-circle-info"></i>
          </div>
        </div>
        <a
          v-if="addon.url"
          class="addon-card__block__body__detail flex-no-shrink -mt-16 md:mt-0 px-8 cursor-pointer text-sm text-blue self-end no-underline"
          :href="addonUrl(addon.url)"
          target='_blank'>
          <span class='mr-4'>{{ $t('watchDetail') }}</span>
          <i class="owl-direction-right"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import owlsight from 'owlsight/src/tailwind.js'
import contentWithCover from '@/components/modals/contentWithCover'
import _ from 'lodash'
import photoSwipe from '@/components/photoSwipe'
export default {
  name: 'addon-card',
  props: {
    addon: {
      type: Object,
      required: true
    }
  },
  components: {
    photoSwipe
  },
  data () {
    return {
      number: 0,
      owlsight: owlsight,
      selectedDate: null,
      selectedSession: null,
      datePickerStyles: {
        wrapper: {
          background: '#fff',
          color: owlsight.colors.red,
          borderColor: owlsight.colors.grey.lightest,
          boxShadow: owlsight.shadows.default
        },
        dayCellNotInMonth: {
          color: owlsight.colors['grey-dark']
        },
        disabledAttribute: {
          contentStyle: {
            backgroundColor: owlsight.colors['grey-lightest'],
            color: owlsight.colors['grey-light']
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'selectedCurrency',
      'isTouchDevice',
      'order/addons',
      'search/dateRange'
    ]),
    isGoods: function () {
      return this.addon.type === 'hotel-goods'
    },
    restrictI18n: function () {
      return this.isGoods ? this.$t('restrict') : this.$t('restrictionsPerGroup')
    },
    personLimit: function () {
      return this.addon.unit_type === 'person' || this.addon.unit_type === 'piece'
        ? this.addon.person_limit.min : 1
    },
    availableDates: function () {
      if (_.isEmpty(this.addon.stocks)) {
        return []
      } else {
        const dates = _.chain(this.addon.stocks).map(stock => stock.date).reverse().value()
        // 商品預設日期為start date, 其餘行程類則為end date
        const date = this.isGoods ? this.$dayjs(this['search/dateRange']?.start).format('YYYY-MM-DD') : this.$dayjs(this['search/dateRange']?.end).format('YYYY-MM-DD')
        const isIncludeDate = dates.includes(date)

        // 若包含 date 則預設日期為 date
        this.onDateArraySorted(isIncludeDate ? date : dates[dates.length - 1])
        return dates
      }
    },
    sessionsOptions: function () {
      if (_.isEmpty(this.addon.stocks)) {
        return []
      } else {
        const options = []
        const relateDate = _.find(this.addon.stocks, stock => stock.date === this.$dayjs(this.selectedDate).format('YYYY-MM-DD'))
        for (const session of relateDate.sessions) {
          if (session.stock < 0) session.stock = 0
          options.push(session)
        }
        return _.sortBy(options, 'time')
      }
    },
    inStock: function () {
      const addonIndex = _.findIndex(this['order/addons'], addon => addon.id === this.addon.id)
      if (!_.isEmpty(this.selectedSession)) {
        if (addonIndex > -1) {
          const relativeSessions = _.filter(this['order/addons'][addonIndex].items, _.matches({ 'date': this.selectedDate, 'session': this.selectedSession.time }))
          const qtyInOrder = _.sumBy(relativeSessions, item => item.qty)
          return this.selectedSession.stock - qtyInOrder
        }
        return this.selectedSession.stock
      }
      return 0
    },
    /*
    // DEPRECATED: for v-calendar v0.x
    attributes: function () {
      const attrs = []
      if (!_.isEmpty(this.addon.stocks)) {
        for (const stock of this.addon.stocks) {
          const sessionArray = []
          for (const session of stock.sessions) {
            sessionArray.push(this.parseTime(session.time))
          }
          const item = {
            key: 'today',
            dates: stock.date,
            highlight: {
              backgroundColor: owlsight.colors['blue-lightest']
            },
            contentStyle: {
              color: owlsight.colors['blue-darkest']
            },
            popover: {
              label: this.isTouchDevice || this.isGoods ? null : sessionArray.join(', ')
            }
          }
          attrs.push(item)
        }
      }
      return attrs
    },
    */
    attributes: function () {
      const attrs = []
      if (!_.isEmpty(this.addon.stocks)) {
        for (const stock of this.addon.stocks) {
          const sessionArray = []
          for (const session of stock.sessions) {
            sessionArray.push(this.parseTime(session.time))
          }
          const item = {
            key: 'today',
            dates: stock.date,
            highlight: {
              backgroundColor: owlsight.colors['blue-lightest']
            },
            contentStyle: {
              color: owlsight.colors['blue-darkest']
            },
            popover: false
          }
          attrs.push(item)
        }
      }
      return attrs
    },
    isSoldOut: function () {
      const noStock = _.isEmpty(this.addon.stocks)
      if (noStock) {
        return true
      } else {
        return false
      }
    },
    hasCancelPolicy: function () {
      return !_.isEmpty(this.addon.cancel_policy)
    },
    isTimeNull: function () {
      if (this.selectedSession) {
        return _.isEmpty(this.selectedSession.time)
      }
      return true
    },
    isExpGroupType: function () {
      return !this.addon.is_hotel_self_addon && this.addon.unit_type === 'group'
    },
    cannotAddToCart: function ({ inStock, personLimit, number }) {
      return (inStock <= 0) || (inStock - personLimit < 0) || (number < 1)
    }
  },
  methods: {
    ...mapMutations([
      'order/ADD_ADDONS'
    ]),
    addonUrl (url) {
      const search = (new URL(url)).search
      const param = search === ''
        ? '?bepv' : '&bepv'
      return `${url}${param}`
    },
    onAddAddon: function (session, unit) {
      this['order/ADD_ADDONS']({
        name: this.addon.name,
        id: this.addon.id,
        date: this.selectedDate,
        session: this.selectedSession.time,
        qty: this.number,
        currency: this.selectedSession.currency,
        price: session.price,
        price_without_fee: session.price_without_fee,
        unit: unit,
        cancel_policy: this.addon.cancel_policy,
        type: this.addon.type,
        feeConfig: session.fee_configs
      })
      this.number = this.personLimit
    },
    remainStock: function (option) {
      const addonIndex = _.findIndex(this['order/addons'], addon => addon.id === this.addon.id)
      if (addonIndex > -1) {
        const relativeSessions = _.filter(this['order/addons'][addonIndex].items, _.matches({ 'date': this.selectedDate, 'session': option.time }))
        const qtyInOrder = _.sumBy(relativeSessions, item => item.qty)
        return option.stock - qtyInOrder
      } else {
        return option.stock
      }
    },
    onDateArraySorted: function (date) {
      this.selectedDate = this.$dayjs(date).toDate()
    },
    parseTime: function (time) {
      const formated = this.$dayjs().format('YYYY/MM/DD ') + time
      let timeParse = this.$dayjs(formated).format('HH:mm')
      if (!time) {
        timeParse = this.$t('unlimitedTime')
      }
      return timeParse
    },
    openPhotoSwipe: function (info) {
      this.$refs.photoSwipe.open(info)
    },
    openCancelPolicy: function () {
      this.$modal.show(contentWithCover, {
        isFeatures: false,
        context: this.$options.filters.contentParser(this.addon.cancel_policy)
      },
      {
        adaptive: true,
        name: 'features-modal',
        height: 'auto',
        width: '100%',
        maxWidth: 450,
        scrollable: true
      })
    },
    formatSpendTime (minutes) {
      if (minutes < 60) {
        return `${minutes} ${this.$t('minute')}`
      } else {
        const hours = Math.floor(minutes / 60)
        const remainMinutes = minutes % 60
        return `${hours} ${this.$t('hour')} ${remainMinutes} ${this.$t('minute')}`
      }
    }
  },
  watch: {
    selectedDate: function () {
      this.selectedSession = this.sessionsOptions[0]
    },
    selectedSession: {
      handler: function () {
        this.number = this.personLimit
      },
      immediate: true
    }
  }
}
</script>

<style lang='sass' scoped>
.addon-card
  @apply border-b-1 border-grey-lighter
  @screen md
    @apply border-none
  &__element__datepicker
    cursor: pointer
    padding:.75rem
    min-width: 9.5rem
    margin-right: 1rem
    border-radius:.375rem
    @apply bg-grey-lightest
    color: #686e74
  &__block
    &__sold-out
      right: 0
      top: 1px
    &__head
      &__gallery
        height: 375px
        @apply border-1 flex-no-shrink w-full
        @screen md
          width: 400px
        @screen lg
          @apply w-full
        @screen xl
          width: 400px
        .cover
          &:hover .zoomMask
            @apply opacity-100
            i
              @apply opacity-100
          .zoomMask
            @apply w-full h-full flex items-center justify-center opacity-0 cursor-pointer
            transition: opacity 0.3s ease-out
            background: radial-gradient(ellipse at center, rgba(0,0,0,0.20) 0%,rgba(0,0,0,0.55) 100%)
            i
              @apply text-white text-6xl opacity-0
              transition: opacity 0.3s ease-out
      &__selections
        &__row
          // @apply flex
          label
            @apply text-grey-dark font-medium mb-8
          span:not(.os-tag)
            @apply text-grey-darkest

          .vue-numeric-input
            @apply mt-0 #{!important}
            &.w-half
              @apply mb-16 #{!important}
              @screen sm
                @apply w-1/2 mb-0 #{!important}
          .addon-btn
            height: 38px
            @apply py-0

  &.is-sold-out
    pointer-events: none
    opacity: .6
.selfHostTitle
  @apply relative text-grey-darker
  &:after
    content: ''
    @apply absolute border-b-2 border-green
    width: 100%
    left: 0
    bottom: -1px
</style>
