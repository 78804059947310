<template>
  <div class='addon-content'>
    <h2 class='mb-24 md:mb-40 font-medium'>{{ $t('addAddons') }}</h2>
    <addonCard v-for="addon in sortedAddons" :addon="addon" :key="addon.id" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import addonCard from '@/views/Addon/components/addonCard'
export default {
  name: 'addon-content',
  components: {
    addonCard
  },
  computed: {
    ...mapGetters([
      'addon/addons',
      'addon/isLoadingAddons'
    ]),
    sortedAddons: function () {
      return this['addon/addons']
    }
  }
}
</script>

<style lang='sass' scoped>

</style>
