var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "addon" },
    [
      this["addon/isLoadingAddons"]
        ? [_c("div", { staticClass: "h-screen" }, [_c("loading")], 1)]
        : [
            _c("processSteps"),
            _c("section", { staticClass: "container flex" }, [
              _c(
                "div",
                { staticClass: "addon__body__rooms flex__main-panel lg:pr-12" },
                [_c("addonContent")],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "addon__body__total flex__aside-panel lg:pl-12"
                },
                [_c("orderTotalAside")],
                1
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }