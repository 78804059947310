var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "addon-card relative mb-24 md:mb-40",
      class: { "is-sold-out": _vm.isSoldOut }
    },
    [
      _c("div", { staticClass: "mb-8 hidden md:block" }, [
        _vm.addon.is_hotel_self_addon
          ? _c(
              "span",
              {
                staticClass:
                  "os-tag text-sm os-tag-solid os-tag-green inline-block font-bold"
              },
              [_vm._v(_vm._s(_vm.$t("primeGoods")))]
            )
          : _vm._e(),
        _c(
          "h4",
          {
            staticClass: "text-grey-darker font-semibold inline-block mr-88",
            class: { selfHostTitle: _vm.addon.is_hotel_self_addon }
          },
          [_vm._v(_vm._s(_vm._f("contentParser")(_vm.addon.name)))]
        )
      ]),
      !_vm.addon.is_hotel_self_addon
        ? _c("div", { staticClass: "text-sm text-grey-darkest mb-8" }, [
            _vm._v(_vm._s(_vm.$t("addon.travelRegistration")))
          ])
        : _vm._e(),
      _c("div", { staticClass: "addon-card__block" }, [
        _c(
          "div",
          {
            staticClass:
              "addon-card__block__head flex flex-col md:flex-row lg:flex-col xl:flex-row md:border-b-1 border-grey-lighter"
          },
          [
            _c(
              "div",
              { staticClass: "addon-card__block__head__gallery" },
              [
                _c(
                  "div",
                  {
                    staticClass: "w-full h-full bg-cover bg-center cover",
                    style: {
                      backgroundImage:
                        "url('" + _vm.addon.cover_images[0] + "')"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.openPhotoSwipe()
                      }
                    }
                  },
                  [_vm._m(0)]
                ),
                _c("photoSwipe", {
                  ref: "photoSwipe",
                  attrs: { photos: _vm.addon.cover_images }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "addon-card__block__head__selections w-full pt-16 pb-0 md:p-16 flex flex-col justify-between"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "addon-card__block__head__selections__row mb-24"
                  },
                  [
                    _c("div", { staticClass: "block md:hidden" }, [
                      _vm.addon.is_hotel_self_addon
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "os-tag text-sm os-tag-solid os-tag-green inline-block font-bold"
                            },
                            [_vm._v(_vm._s(_vm.$t("primeGoods")))]
                          )
                        : _vm._e(),
                      _c(
                        "h4",
                        {
                          staticClass:
                            "text-grey-darker font-semibold mb-8 inline-block",
                          class: {
                            selfHostTitle: _vm.addon.is_hotel_self_addon
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("contentParser")(_vm.addon.name))
                          )
                        ]
                      )
                    ]),
                    _vm.selectedSession
                      ? _c(
                          "span",
                          { staticClass: "text-sm block" },
                          [
                            _vm._v(_vm._s(_vm.selectedCurrency) + " "),
                            _c(
                              "big",
                              { staticClass: "text-blue text-lg font-medium" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.selectedSession.price_without_fee,
                                      {
                                        inputCurrency:
                                          _vm.selectedSession.currency,
                                        isBonus: true
                                      }
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" / " + _vm._s(_vm.$t(_vm.addon.unit)))
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isSoldOut
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "addon-card__block__sold-out os-tag os-tag-outline os-tag-red rounded-full text-sm static md:absolute"
                          },
                          [_vm._v(_vm._s(_vm.$t("endSale")))]
                        )
                      : _vm._e()
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "addon-card__block__head__selections__row flex flex items-start justify-between mb-24 flex-1"
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isGoods,
                            expression: "!isGoods"
                          }
                        ],
                        staticClass:
                          "addon-card__block__head__selections__time text-sm flex flex-col"
                      },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("totalTime")))]),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatSpendTime(_vm.addon.spend_time))
                          )
                        ])
                      ]
                    ),
                    !_vm.addon.is_hotel_self_addon &&
                    _vm.addon.unit_type === "group"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "addon-card__block__head__selections__limit text-sm flex flex-col"
                          },
                          [
                            _c("label", [_vm._v(_vm._s(_vm.restrictI18n))]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.addon.person_limit.min) +
                                  " - " +
                                  _vm._s(_vm.addon.person_limit.max) +
                                  " " +
                                  _vm._s(
                                    _vm.isExpGroupType
                                      ? ""
                                      : _vm.$t(_vm.addon.unit)
                                  )
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "addon-card__block__head__selections__row flex text-sm mb-16 flex-col"
                  },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.isGoods
                            ? _vm.$t("goodsDate")
                            : _vm.$t("travelDate")
                        )
                      )
                    ]),
                    _c("v-date-picker", {
                      attrs: {
                        "is-required": "",
                        attributes: _vm.attributes,
                        "available-dates": _vm.availableDates
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var togglePopover = ref.togglePopover
                            return [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "input input-icon addon-card__element__datepicker",
                                  on: { click: togglePopover }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm
                                          .$dayjs(_vm.selectedDate)
                                          .format("YYYY/MM/DD")
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c("i", {
                                staticClass: "owl-calendar",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.selectedDate,
                        callback: function($$v) {
                          _vm.selectedDate = $$v
                        },
                        expression: "selectedDate"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isGoods,
                        expression: "!isGoods"
                      }
                    ],
                    staticClass:
                      "addon-card__block__head__selections__row flex text-sm mb-16 flex-col"
                  },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("selectionPeriod")))]),
                    _c("multiSelect", {
                      attrs: {
                        options: _vm.sessionsOptions,
                        "allow-empty": false,
                        searchable: false,
                        "show-labels": false,
                        showPointer: _vm.isTouchDevice ? false : true,
                        placeholder: _vm.$t("pleaseSelectATimeSlot"),
                        disabled: _vm.isTimeNull
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.parseTime(option.time)) +
                                  " - " +
                                  _vm._s(_vm.$t("remain")) +
                                  " " +
                                  _vm._s(_vm.remainStock(option)) +
                                  "\n            "
                              )
                            ]
                          }
                        },
                        {
                          key: "singleLabel",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _c("i", {
                                staticClass: "owl-circle-clock-o text-grey mr-4"
                              }),
                              _c("span", { staticClass: "text-md" }, [
                                _vm._v(_vm._s(_vm.parseTime(option.time)))
                              ])
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.selectedSession,
                        callback: function($$v) {
                          _vm.selectedSession = $$v
                        },
                        expression: "selectedSession"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "addon-card__block__head__selections__row flex text-sm flex-col"
                  },
                  [
                    _c("div", { staticClass: "stock-label mb-8" }, [
                      _c("label", { staticClass: "mr-16" }, [
                        _vm._v(
                          _vm._s(_vm.$t("quantity")) +
                            " (" +
                            _vm._s(_vm.$t(_vm.addon.unit)) +
                            ")"
                        )
                      ]),
                      _c("small", { staticClass: "text-sm text-grey" }, [
                        _vm._v(
                          _vm._s(_vm.$t("stocks")) + "：" + _vm._s(_vm.inStock)
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "stock-btns flex items-center flex-col sm:flex-row"
                      },
                      [
                        _c("vue-numeric-input", {
                          staticClass: "w-half sm:mr-8",
                          attrs: {
                            min: _vm.personLimit,
                            max: _vm.inStock,
                            step: 1
                          },
                          model: {
                            value: _vm.number,
                            callback: function($$v) {
                              _vm.number = $$v
                            },
                            expression: "number"
                          }
                        }),
                        _c(
                          "button",
                          {
                            staticClass:
                              "addon-btn btn btn-ghost btn-blue btn-xs w-full sm:w-1/2 sm:ml-8",
                            attrs: { disabled: _vm.cannotAddToCart },
                            on: {
                              click: function($event) {
                                return _vm.onAddAddon(
                                  _vm.selectedSession,
                                  _vm.addon.unit
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("addAddons")) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "addon-card__block__body flex flex-col md:flex-row md:items-end md:justify-between py-16 md:px-16"
          },
          [
            _c(
              "div",
              {
                staticClass: "addon-card__block__body__content",
                class: { "w-full": _vm.addon.note }
              },
              [
                _vm.addon.introduction
                  ? _c(
                      "div",
                      {
                        staticClass: "mb-8",
                        class: {
                          "border-b border-grey-lighter mb-16": _vm.addon.note
                        }
                      },
                      [
                        _vm.addon.note
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-sm text-grey-darker font-bold mb-8"
                              },
                              [
                                _c("i", { staticClass: "owl-caret-right" }),
                                _vm._v(_vm._s(_vm.$t("introduction")))
                              ]
                            )
                          : _vm._e(),
                        _c("p", {
                          staticClass:
                            "whitespace-pre-wrap text-sm text-grey-darker leading-normal mb-16",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$options.filters.contentParser(
                                _vm.addon.introduction
                              )
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm.addon.note
                  ? _c("div", { staticClass: "mb-8" }, [
                      _c(
                        "div",
                        {
                          staticClass: "text-sm text-grey-darker font-bold mb-8"
                        },
                        [
                          _c("i", { staticClass: "owl-caret-right" }),
                          _vm._v(_vm._s(_vm.$t("note")))
                        ]
                      ),
                      _c("p", {
                        staticClass:
                          "whitespace-pre-wrap text-sm text-grey-darker leading-normal mb-16",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.contentParser(_vm.addon.note)
                          )
                        }
                      })
                    ])
                  : _vm._e(),
                _vm.hasCancelPolicy
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "addon-card__block__body__content__cancel-policy text-sm text-grey-dark cursor-pointer inline-block",
                        on: { click: _vm.openCancelPolicy }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("cancelPolicy")))]),
                        _c("i", { staticClass: "owl-status-circle-info" })
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm.addon.url
              ? _c(
                  "a",
                  {
                    staticClass:
                      "addon-card__block__body__detail flex-no-shrink -mt-16 md:mt-0 px-8 cursor-pointer text-sm text-blue self-end no-underline",
                    attrs: {
                      href: _vm.addonUrl(_vm.addon.url),
                      target: "_blank"
                    }
                  },
                  [
                    _c("span", { staticClass: "mr-4" }, [
                      _vm._v(_vm._s(_vm.$t("watchDetail")))
                    ]),
                    _c("i", { staticClass: "owl-direction-right" })
                  ]
                )
              : _vm._e()
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "zoomMask" }, [
      _c("i", { staticClass: "owl-search" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }